<template>
  <div
    style="
      width: 100%;
      height: 100vh;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
    "
  >
    <section class="main-content">
      <slot>
        <p>Aqui va el contenido</p>
      </slot>
    </section>

    <section class="actionbar" :class="{ 'actionbar--hidden': !actionbarVisible }">
      <div class="d-flex justify-content-end position-relative" :style="$route.name == 'project-list' ? `margin-bottom: -14px;` : ''">
        <select-lenguage v-if="session == true" @cambio="changeLenguage"></select-lenguage>
      </div>
      <button
        class="actionbar__handle"
        tabindex="-1"
        type="button"
        @click="actionbarVisible = !actionbarVisible"
      >
        <span v-if="actionbarVisible">{{ $t('lang.hide.msg') }}</span>
        <span v-else>{{ $t('lang.show.msg') }}</span>
      </button>

      <slot  name="actionbar">
        <p v-if="session == true" >Aqui va el actionbar</p>
      </slot>
    </section>
  </div>
</template>

<script>
import SelectLenguage from '@/components/SelectLenguage.vue';
export default {
  components: { SelectLenguage },
  props: {
    session:{ type: Boolean, default: true}
  },
  name: 'AppLayout',
  data() {
    return {
      actionbarVisible: true,
    };
  },
  methods: {
    changeLenguage(e) {
      if (e) {
        this.$emit('cambio', true);
      } else {
        this.$emit('cambio', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.main-content {
  overflow-y: hidden;
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.actionbar {
  width: 100%;
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  transition: all ease-in 0.3s;
  flex-grow: 0;

  &--hidden {
    transition: all ease-out 0.3s;
    position: absolute;
    top: 100%;
  }

  @include media-breakpoint-up(md) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__handle {
    position: absolute;
    left: 0;
    bottom: 100%;
    padding: 0.25rem 1rem;
    background: rgba(216, 216, 216, 0.788);
    font-size: 0.75rem;
    border-radius: 0.25rem 0.25rem 0 0;
    width: 5rem;
  }
}
</style>
