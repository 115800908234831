import apiClient from './api-client';

export default {
  getProjects(perPage, page) {
    return apiClient
      .get(`/projects?limit=${perPage}&page=${page}`)
      .then((response) => response.data);
  },
  getProject(id) {
    return apiClient.get(`/projects/${id}`);
  },
  createProject(project) {
    return apiClient.post('/projects', project);
  },
  updateImage(id, data) {
    return apiClient.post(`/projects/${id}/logo`, data);
  },
  getLogs(id) {
    return apiClient.get(`/projects/${id}/logs`);
  },
  getVideos(id) {
    return apiClient.get(`/projects/${id}/videos`);
  },
  getReports(id, type) {
    return apiClient.get(`/projects/${id}/reports`, {
      params: { type },
    });
  },
  addReport(projectId, data) {
    return apiClient.post(`/projects/${projectId}/reports`, data);
  },
};
