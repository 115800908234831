import config from '@/config';
import projectService from '@/services/project';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  projects: [],
  projectsTotal: 0,
  project: {},
  perPage: 5,
  project_logs: false,
  project_videos: false,
  reports: [],
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  ADD_PROJECT(state, project) {
    state.projects.push(project);
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_PROJECTS_TOTAL(state, projectsTotal) {
    state.projectsTotal = projectsTotal;
  },
  SET_PROJECT(state, project) {
    state.project = project;
  },
  SET_PROJECT_LOGS(state, logs) {
    state.project_logs = logs;
  },
  SET_PROJECT_VIDEOS(state, videos) {
    state.project_videos = videos;
  },
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  ADD_REPORT(state, report) {
    state.reports.unshift(report);
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  // createProject({ commit, dispatch }, project) {
  //   return projectService
  //     .createProject(project)
  //     .then(() => {
  //       commit('ADD_PROJECT', project);
  //       commit('SET_PROJECT', project);

  //       const notification = {
  //         type: 'success',
  //         message: 'Tu proyecto fué creado!',
  //       };
  //       dispatch('notification/add', notification, { root: true });
  //     })
  //     .catch((error) => {
  //       const notification = {
  //         type: 'error',
  //         message: 'Ocurrió un problema al crear tu proyecto: ' + error.message,
  //       };
  //       dispatch('notification/add', notification, { root: true });
  //       throw error;
  //     });
  // },

  fetchProjects({ commit, dispatch, state }, { page }) {
    return projectService
      .getProjects(state.perPage, page)
      .then((response) => {
        // commit('SET_PROJECTS_TOTAL', parseInt(response.headers['x-total-count']));
        commit('SET_PROJECTS', response.data);
        return response.data;
      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: 'Existe un problema al cargar los proyectos: ' + error.message,
        };
        dispatch('notification/add', notification, { root: true });
      });
  },


  addReport({ commit, dispatch }, { projectId, data }) {
    return projectService
      .addReport(projectId, data)
      .then((response) => {
        commit('ADD_REPORT', response.data.data);
        return response.data.data;
      })
      .catch((error) => {
        dispatch('notification/addError', error.response.data.message, { root: true });
        throw error;
      });
  },

  fetchProject({ commit, getters, state }, id) {
    if (id == state.project.id) {
      return state.project;
    }

    const project = getters.getProjectById(id);

    if (project) {
      commit('SET_PROJECT', project);
      return project;
    } else {
      return projectService.getProject(id).then((response) => {
        commit('SET_PROJECT', response.data.data);
        return response.data.data;
      });
    }
  },

  updateImageProject({ commit }, { project, data }) {
    return projectService.updateImage(project.id, data).then((response) => {
      commit('SET_PROJECT', response.data.data);
      return response.data;
    });
  },

  fetchProjectLogs({ commit }, id) {
    return projectService.getLogs(id).then((response) => {
      commit('SET_PROJECT_LOGS', true);
      return response.data;
    });
  },

  fetchProjectVideos({ commit }, id) {
    return projectService.getVideos(id).then((response) => {
      commit('SET_PROJECT_VIDEOS', true);
      return response.data;
    });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
export const getters = {
  getProjectById: (state) => (id) => {
    return state.projects.find((project) => project.id === id);
  },
  projectLogoUrl: (state) => state.project.logo_url ?? config.PROJECT_LOGO_PLACEHOLDER,
  projectName: (state) => state.project.name ?? '',
};
